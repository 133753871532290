import { useEffect } from 'react';
import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { Messages } from './useSuperMutation';
import useSuperOptions from './useSuperOptions';
import { SuperOptions } from './useSuperOptions';

function fillDefaultOptions(options?: SuperOptions) {
  return {
    showSpinner:
      options?.showSpinner === undefined ? false : options.showSpinner,
    showSuccessMessage:
      options?.showSuccessMessage === undefined
        ? false
        : options.showSuccessMessage,
    showErrorMessage:
      options?.showErrorMessage === undefined ? true : options.showErrorMessage,
    successMessageType:
      options?.successMessageType === undefined
        ? 'snackbar'
        : options.successMessageType,
    errorMessageType:
      options?.errorMessageType === undefined
        ? 'snackbar'
        : options.errorMessageType,
  };
}

function useSuperQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  > &
    SuperOptions & { messages?: Messages }
) {
  const handlers = useSuperOptions(fillDefaultOptions(options));

  const query = useQuery<TQueryFnData, TError, TData, TQueryKey>(
    queryKey,
    queryFn,
    {
      ...options,
      onSuccess: data => {
        handlers.showSuccesMessage();
        options?.onSuccess && options?.onSuccess(data);
      },
      onError: error => {
        handlers.showErrorMessage((error as unknown) as Error);
        options?.onError && options?.onError(error);
      },
    }
  );

  useEffect(() => {
    if (query.status === 'loading') {
      handlers.showSpinner();
    }
    if (query.status === 'success' || query.status === 'error') {
      handlers.hideSpinner();
    }
  }, [query.status]);

  return query;
}

export default useSuperQuery;
