import React, { FC, useContext, useState } from 'react';

export const SpinnerContext = React.createContext(
  {} as MessageServiceInterface
);
export interface MessageServiceInterface {
  showSpinner: () => void;
  hideSpinner: () => void;
}

interface Props {
  component: FC<{ isVisible: boolean }>;
  children: React.ReactNode;
}

export const SpinnerProvider: FC<Props> = ({
  component: Component,
  children,
}) => {
  const [requestCount, setRequestCount] = useState(0);

  const showSpinner = function() {
    setRequestCount(prevCount => prevCount + 1);
  };

  const hideSpinner = function() {
    setRequestCount(prevCount => prevCount - 1);
  };
  console.log(requestCount);
  return (
    <SpinnerContext.Provider value={{ showSpinner, hideSpinner }}>
      <Component isVisible={requestCount > 0} />
      {children}
    </SpinnerContext.Provider>
  );
};

const useSpinner = () => {
  const context = useContext(SpinnerContext);
  if (!context.showSpinner) {
    throw new Error('useSpinner debe estar dentro de SpinnerProvider');
  }
  return context;
};

export default useSpinner;
