import { useEffect } from 'react';
import { useSnackbar } from 'notistack';

interface Props {
  title?: string;
  message: string;
  type: 'error' | 'success' | 'info' | 'warning';
  isVisible: boolean;
}

export default function Snackbar({ message, type, isVisible }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    if (isVisible) enqueueSnackbar(message, { variant: type });
  }, [isVisible]);
  return null;
}
