import { useEffect, useState } from 'react';

export type MessageType = {
  message: string;
  type: 'error' | 'success' | 'info' | 'warning';
  duration?: number;
  title?: string;
};

const useBaseMessage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [state, setState] = useState<MessageType>({
    message: '',
    title: undefined,
    type: 'info',
    duration: 7000,
  });

  useEffect(() => {
    if (isVisible) {
      const timeout = setTimeout(
        () => setIsVisible(false),
        state.duration || 7000
      );
      return () => clearTimeout(timeout);
    }
    return () => {};
  }, [isVisible]);

  function showMessage(args: MessageType) {
    setState({ ...args, duration: args.duration || 7000 });
    setIsVisible(true);
  }

  return {
    showMessage,
    isVisible,
    state,
  };
};

export default useBaseMessage;
