import { MessageType } from './useBaseMessage';

interface Props {
  hook: () => (args: MessageType) => void;
}

function useBaseMessangeAsyncSuccess<T>({ hook }: Props) {
  const showMessagge = hook();

  const handlePromise = (
    promise: Promise<T>,
    message?: Omit<MessageType, 'type'>
  ): void => {
    promise.then(() => {
      showMessagge({
        ...message,
        message: message?.message || 'La acción se realizo con éxito',
        type: 'success',
      });
    });
  };

  return handlePromise;
}

export default useBaseMessangeAsyncSuccess;
