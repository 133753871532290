import { CircularProgress } from '@material-ui/core';
import React from 'react';
import './tailwind.css';

interface Props {
  children: React.ReactNode;
  variant?: 'contained' | 'outlined' | 'text' | 'light';
  className?: string;
  disabled?: boolean;
  shape?: 'circle' | 'rounded' | 'default';
  color?: string;
  type?: 'button' | 'submit';
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isLoading?: boolean;
}

const Button = ({
  children,
  variant = 'text',
  className = '',
  disabled = false,
  shape = 'default',
  color = 'primary',
  type = 'button',
  onClick,
  isLoading = false,
}: Props) => {
  const baseStyle =
    'rounded-md font-semibold px-4 py-2.5 transition-all duration-300 ease-in-out focus:outline-none flex items-center justify-center text-xs';

  const shapes = {
    rounded: 'rounded-full px-2 py-2',
    circle: 'rounded-full px-2 py-2 h-10 w-10',
    default: '',
  };

  const classes = {
    contained: `text-white ${
      disabled
        ? `bg-${color}-300 hover:bg-${color}-300 cursor-not-allowed`
        : `bg-${color}-400 hover:bg-${color}-500`
    }`,
    text: `${
      disabled
        ? `text-${color}-400 cursor-not-allowed`
        : `text-${color}-500 hover:bg-${color}-100`
    }`,
    outlined: `border-solid border-2 ${
      disabled
        ? `text-${color}-300 border-${color}-300 cursor-not-allowed`
        : `text-${color}-400 border-${color}-400 hover:bg-${color}-400 hover:text-white`
    }`,
    light: `${
      disabled
        ? `text-${color}-300 bg-${color}-200 cursor-not-allowed`
        : `text-${color}-400 bg-${color}-100 hover:bg-${color}-400 hover:text-white`
    }`,
  };

  const content = (
    <div className="flex items-center">
      {isLoading && (
        <CircularProgress color="primary" size={15} className="mr-4" />
      )}
      {children}
    </div>
  );

  return (
    <button
      onClick={onClick}
      className={`${baseStyle} ${classes[variant]} ${shapes[shape]} ${className}`}
      type={type}
      disabled={disabled}
    >
      {content}
    </button>
  );
};

export default Button;
