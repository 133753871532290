import { useEffect } from 'react';
import { MutationFunction, useMutation, UseMutationOptions } from 'react-query';
import { MessageType } from './messangers/useBaseMessage';
import useSuperOptions, { SuperOptions } from './useSuperOptions';

function fillDefaultOptions(
  options?: SuperOptions & { messages?: Messages }
): SuperOptions & { messages: Messages } {
  return {
    showSpinner:
      options?.showSpinner === undefined ? false : options.showSpinner,
    showSuccessMessage:
      options?.showSuccessMessage === undefined
        ? true
        : options?.showSuccessMessage,
    showErrorMessage:
      options?.showErrorMessage === undefined ? true : options.showErrorMessage,
    successMessageType:
      options?.successMessageType === undefined
        ? 'snackbar'
        : options?.successMessageType,
    errorMessageType:
      options?.errorMessageType === undefined
        ? 'snackbar'
        : options?.errorMessageType,
    messages: options?.messages ?? {
      success: {
        message: 'OK',
        duration: 5000,
      },
    },
  };
}

export interface Messages {
  success: Omit<MessageType, 'type'>;
  error?: Omit<MessageType, 'type'>;
}

function useSuperMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown
>(
  mutationFn: MutationFunction<TData, TVariables>,
  options?: Omit<
    UseMutationOptions<TData, TError, TVariables, TContext>,
    'mutationKey' | 'mutationFn'
  > &
    SuperOptions & { messages?: Messages }
) {
  const handlers = useSuperOptions(fillDefaultOptions(options));

  const mutation = useMutation<TData, TError, TVariables, TContext>(
    mutationFn,
    {
      ...options,
      onSuccess: (data, variables, context) => {
        handlers.showSuccesMessage();
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
      onError: (error, variables, context) => {
        handlers.showErrorMessage((error as unknown) as Error);
        options?.onError && options?.onError(error, variables, context);
      },
    }
  );

  useEffect(() => {
    if (mutation.status === 'loading') {
      handlers.showSpinner();
    }
    if (mutation.status === 'success' || mutation.status === 'error') {
      handlers.hideSpinner();
    }
  }, [mutation.status]);

  return mutation;
}

export default useSuperMutation;
