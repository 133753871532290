import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { FaTimes } from 'react-icons/fa';
import './tailwind.css';

interface Props {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  open: boolean;
  body: React.ReactNode;
  onClose: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

export default function Modal({
  title,
  subtitle,
  open,
  onClose,
  body,
  size = 'sm',
}: Props) {
  return (
    <Dialog open={open} keepMounted onClose={onClose} fullWidth maxWidth={size}>
      <div className="p-8">
        <div className="flex justify-end text-gray-300">
          <FaTimes className="hover:text-blue-500" onClick={onClose} />
        </div>
        <div className="flex flex-col justify-center items-center space-y-2">
          {title && typeof title === 'string' ? (
            <div className="text-gray-700 text-xl font-semibold">{title}</div>
          ) : (
            title
          )}
          {subtitle && typeof subtitle === 'string' ? (
            <div className="text-gray-400 text-sm">{subtitle}</div>
          ) : (
            subtitle
          )}
        </div>
        <div className="mt-4">{body}</div>
      </div>
    </Dialog>
  );
}
