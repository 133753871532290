import React, { FC } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from './Button';
import { FaExclamationTriangle } from 'react-icons/fa';

interface Props {
  isVisible: boolean;
  handleClose: () => void;
  accept: () => any;
  deny: () => any;
  title?: React.ReactNode | string;
  body?: React.ReactNode | string;
}

const VerifyAction: FC<Props> = ({
  isVisible,
  handleClose,
  accept,
  deny,
  title,
  body,
}) => {
  return (
    <div>
      <Dialog open={isVisible} keepMounted onClose={handleClose} fullWidth>
        <div className="w-full h-24 bg-orange-400 flex items-center justify-center">
          <FaExclamationTriangle size="40" color="white" />
        </div>
        <div className="flex flex-col justify-center items-center my-3.5">
          <div className="font-bold text-gray-600 text-lg">{title}</div>
          <div className="font-bold text-gray-500 text-sm mt-1">{body}</div>
        </div>
        <div className="flex justify-evenly p-5">
          <Button onClick={deny} color="gray" variant="light">
            Cancelar
          </Button>
          <Button onClick={accept} color="orange" variant="light">
            Estoy seguro!
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default VerifyAction;
