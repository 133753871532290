import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import { DependencyList } from 'react';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface FetchFn<T, P extends any[] = []> {
  (...args: P): Promise<T>;
}

export interface Query<T> {
  data: T | undefined;
  isLoading: boolean;
  isError: boolean;
  reload: () => Promise<void>;
  error: Error | undefined;
  isFirstLoad: boolean;
}

export interface LifecycleHooks<T> {
  onLoadStart: (state: State<T>) => void;
  onLoadFinish: (state: State<T>) => void;
  onFirstLoadStart: (state: State<T>) => void;
  onFirstLoadFinish: (state: State<T>) => void;
}

export interface Options<T> extends LifecycleHooks<T> {
  automaticFetch: boolean;
}

type Action<T> =
  | { type: 'pending' }
  | { type: 'resolved'; payload: T }
  | { type: 'rejected'; payload: Error };

type State<T> = {
  isLoading: boolean;
  isError: boolean;
  data: T | undefined;
  error: Error | undefined;
  isFirstLoad: boolean;
};

function reducer<T>(state: State<T>, action: Action<T>): State<T> {
  switch (action.type) {
    case 'pending':
      return {
        isError: false,
        isLoading: true,
        data: state.data,
        error: undefined,
        isFirstLoad: state.data === undefined,
      };

    case 'resolved':
      return {
        isError: false,
        isLoading: false,
        data: action.payload,
        error: undefined,
        isFirstLoad: state.isFirstLoad,
      };

    case 'rejected':
      return {
        isError: true,
        isLoading: false,
        data: undefined,
        error: action.payload,
        isFirstLoad: state.isFirstLoad,
      };

    default:
      return state;
  }
}

const initialState = {
  isLoading: false,
  isError: false,
  error: undefined,
  data: undefined,
  isFirstLoad: true,
};

const neutralFunction = () => {};

const useQuery = function<T>(
  fetchFn: FetchFn<T>,
  dependencies: DependencyList = [],
  options?: Partial<Options<T>>
): Query<T> {
  const isSubscribed = useRef(true);
  const firstRender = useRef(true);
  const [state, dispatch] = useReducer<React.Reducer<State<T>, Action<T>>>(
    reducer,
    initialState
  );
  const lifecycleHooks: LifecycleHooks<T> = {
    onLoadStart: options?.onLoadStart ?? neutralFunction,
    onLoadFinish: options?.onLoadFinish ?? neutralFunction,
    onFirstLoadStart: options?.onFirstLoadStart ?? neutralFunction,
    onFirstLoadFinish: options?.onFirstLoadFinish ?? neutralFunction,
  };
  const automaticFetch = options?.automaticFetch ?? true;

  const reload = useCallback(() => {
    dispatch({ type: 'pending' });
    return fetchFn()
      .then(response => {
        if (isSubscribed.current) {
          dispatch({ type: 'resolved', payload: response });
        }
      })
      .catch(res => {
        console.error(res);
        if (isSubscribed.current) {
          dispatch({ type: 'rejected', payload: res });
        }
      });
  }, dependencies);

  React.useEffect(() => {
    if (!automaticFetch) {
      return;
    }
    reload();
  }, [reload]);

  React.useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }

    if (!isSubscribed.current) {
      return;
    }
    if (state.isLoading) {
      lifecycleHooks.onLoadStart(state);
      if (state.isFirstLoad) {
        lifecycleHooks.onFirstLoadStart(state);
      }
    }
    if (!state.isLoading) {
      lifecycleHooks.onLoadFinish(state);
      if (state.isFirstLoad) {
        lifecycleHooks.onFirstLoadFinish(state);
      }
    }
  }, [state]);

  return useMemo(() => ({ ...state, reload }), [state, reload]);
};

export default useQuery;
