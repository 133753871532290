import React, { FC, useContext } from 'react';
import useBaseMessage, { MessageType } from './useBaseMessage';

interface Context {
  showMessage: (args: MessageType) => void;
}

const MessageContext = React.createContext({} as Context);

interface Props {
  component: FC<Omit<MessageType, 'duration'> & { isVisible: boolean }>;
  children: React.ReactNode;
}

export const MessageProvider: FC<Props> = ({
  component: Component,
  children,
}) => {
  const { showMessage, isVisible, state } = useBaseMessage();

  return (
    <MessageContext.Provider value={{ showMessage }}>
      <Component {...state} isVisible={isVisible} />
      {children}
    </MessageContext.Provider>
  );
};

const useMessage = (): ((args: MessageType) => void) => {
  const context = useContext(MessageContext);
  if (!context.showMessage) {
    throw new Error('useMessage must be used inside MessageProvider');
  }
  return context.showMessage;
};

export default useMessage;
