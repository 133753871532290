import { CircularProgress } from '@material-ui/core';
import { AutocompleteInputChangeReason } from '@material-ui/lab';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
} from 'formik-material-ui-lab';
import React, { useEffect, useRef, useState, ComponentProps } from 'react';

function AutocompleteAsync<T>(
  props: ComponentProps<typeof Autocomplete> & {
    onChange?: (value: T | T[]) => void;
    fetchOptions: (text: string) => Promise<T[]>;
  }
) {
  const { field } = props;
  const [options, setOptions] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { onChange, ...otherProps } = props;
  const debounce = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    onChange && onChange(field.value);
  }, [field.value, onChange]);

  function handleInputChange(
    _: any,
    value: string,
    reason: AutocompleteInputChangeReason
  ) {
    if (reason === 'input') {
      setIsLoading(true);
      clearTimeout(debounce.current!);
      debounce.current = setTimeout(async () => {
        const response = await props.fetchOptions(value);
        setOptions(response);
        setIsLoading(false);
      }, 1000);
    }
  }

  return (
    <Autocomplete
      {...otherProps}
      loading={isLoading}
      options={options}
      noOptionsText="No se han encontrado resultados"
      loadingText="Cargando..."
      filterOptions={props.filterOptions || ((options: any) => options)}
      onInputChange={handleInputChange}
      renderInput={(params: AutocompleteRenderInputParams) => {
        const baseInput = props.renderInput(params);
        if (React.isValidElement(baseInput)) {
          return React.cloneElement(baseInput, {
            InputProps: {
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            },
          });
        }
        return null;
      }}
    />
  );
}

export default AutocompleteAsync;
