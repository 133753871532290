import React from 'react';
import MUIDataTable, { MUIDataTableProps } from 'mui-datatables';
import { localizationOptions } from './localization';

const Table = ({ options, ...props }: MUIDataTableProps) => {
  return (
    <MUIDataTable
      options={{
        elevation: 0,
        selectableRows: 'none',
        ...localizationOptions,
        ...options,
      }}
      {...props}
    />
  );
};

export default Table;
