import { MessageType } from './useBaseMessage';

interface Props {
  hook: () => (args: MessageType) => void;
}

function useBaseMessangerAsyncError<T>({ hook }: Props) {
  const showMessagge = hook();

  const handlePromise = (
    promise: Promise<T>,
    message?: Omit<MessageType, 'type'>
  ): void => {
    promise.catch((error: Error) => {
      console.error(error);
      showMessagge({
        ...message,
        message: message?.message || error.message,
        type: 'error',
      });
    });
  };

  return handlePromise;
}

export default useBaseMessangerAsyncError;
