import React, { useEffect, useRef } from 'react';

interface State<T> {
  data: T | undefined;
  isLoading: boolean;
  isError: boolean;
  error: Error | undefined;
}

export interface LifecycleHooks<StateType, ArgType> {
  onMutationStart: (state: State<StateType>, arg: ArgType) => void;
  onMutationFinish: (state: State<StateType>, arg: ArgType) => void;
}

const neutralFunction = () => {};

const initialState = {
  data: undefined,
  isLoading: false,
  isError: false,
  error: undefined,
};

function useMutation<StateType, ArgType>(
  callback:
    | ((arg1: ArgType, ...args: any[]) => Promise<StateType>)
    | (() => Promise<StateType>),
  lifecycleHooks?: Partial<LifecycleHooks<StateType, ArgType>>
) {
  const [state, setState] = React.useState<State<StateType>>(initialState);
  const isSubscribed = useRef(true);

  async function mutate(arg: ArgType, ...args: any[]) {
    if (!isSubscribed.current) return;
    const _lifecycleHooks: LifecycleHooks<StateType, ArgType> = {
      onMutationStart: lifecycleHooks?.onMutationStart ?? neutralFunction,
      onMutationFinish: lifecycleHooks?.onMutationFinish ?? neutralFunction,
    };
    setState(state => {
      const nextState = { ...state, isLoading: true };
      _lifecycleHooks.onMutationStart(nextState, arg);
      return nextState;
    });

    try {
      const res = await callback(arg, ...args);
      if (!isSubscribed.current) return;
      setState(state => {
        const nextState = {
          ...state,
          isLoading: false,
          data: res,
          isError: false,
        };
        _lifecycleHooks.onMutationFinish(nextState, arg);
        return nextState;
      });
    } catch (error) {
      console.error(error);
      if (!isSubscribed.current) return;
      setState(state => {
        const nextState = {
          ...state,
          isLoading: false,
          isError: true,
          error: error as Error,
        };

        _lifecycleHooks.onMutationFinish(nextState, arg);
        return nextState;
      });
      throw new Error(error);
    }
  }

  useEffect(() => {
    return () => {
      isSubscribed.current = false;
    };
  }, []);

  return { mutate, ...state };
}

export default useMutation;
