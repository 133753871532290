import { MessageType } from './useBaseMessage';

interface Props {
  hook: () => (args: MessageType) => void;
}

function useBaseMessangerError({ hook }: Props) {
  const showMessagge = hook();

  const showMessage = (
    error: Error,
    message?: Omit<MessageType, 'type'>
  ): void => {
    console.error(error);
    showMessagge({
      ...message,
      message: message?.message || error.message,
      type: 'error',
    });
  };

  return showMessage;
}

export default useBaseMessangerError;
