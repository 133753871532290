import { MessageType } from './useBaseMessage';

interface Props {
  hook: () => (args: MessageType) => void;
}

function useBaseMessangeSuccess({ hook }: Props) {
  const showMessagge = hook();

  const showMessage = (message?: Omit<MessageType, 'type'>): void => {
    showMessagge({
      ...message,
      message: message?.message || 'La acción se realizo con éxito',
      type: 'success',
    });
  };

  return showMessage;
}

export default useBaseMessangeSuccess;
