import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';

interface Props {
  isVisible: boolean;
}

const Spinner = ({ isVisible }: Props) => {
  return (
    <Backdrop open={isVisible} style={{ zIndex: 1000 }}>
      <div className="flex bg-white space-x-4 p-4 font-bold text-grey-600 rounded-md items-center">
        <span>Cargando</span>
        <CircularProgress color="primary" size={30} />
      </div>
    </Backdrop>
  );
};

export default Spinner;
