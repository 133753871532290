import React, { FC, ReactNode, useContext, useRef, useState } from 'react';

type Callback = () => any;

type AskForVerificationType = (args: {
  onAccept: Callback;
  onDeny?: Callback;
  title?: string | ReactNode;
  body?: string | ReactNode;
}) => void;

interface VerifyActionContextType {
  askForVerification: AskForVerificationType;
}

export const VerifyActionContext = React.createContext(
  {} as VerifyActionContextType
);

interface Props {
  component: FC<{
    isVisible: boolean;
    handleClose: () => void;
    accept: () => any;
    deny: () => any;
    title?: React.ReactNode | string;
    body?: React.ReactNode | string;
  }>;
  children: React.ReactNode;
}

export const VerifyActionProvider: FC<Props> = ({
  component: Component,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState<string | ReactNode | undefined>(undefined);
  const [body, setBody] = useState<string | ReactNode | undefined>(undefined);
  const acceptCallback = useRef<Callback | undefined>(undefined);
  const denyCallback = useRef<Callback | undefined>(undefined);

  const askForVerification: AskForVerificationType = args => {
    acceptCallback.current = args.onAccept;
    denyCallback.current = args.onDeny;
    setTitle(args.title);
    setBody(args.body);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const onAccept = () => {
    if (acceptCallback.current) {
      acceptCallback.current();
    }
    handleClose();
  };
  const onDeny = () => {
    if (denyCallback.current) {
      denyCallback.current();
    }
    handleClose();
  };

  return (
    <VerifyActionContext.Provider value={{ askForVerification }}>
      <Component
        isVisible={isOpen}
        handleClose={handleClose}
        accept={onAccept}
        deny={onDeny}
        title={title}
        body={body}
      />
      {children}
    </VerifyActionContext.Provider>
  );
};

const useVerifyAction = () => {
  const context = useContext(VerifyActionContext);
  if (!context.askForVerification) {
    throw new Error(
      'useVerifyAction debe estar dentro de VerifyActionProvider'
    );
  }
  return context.askForVerification;
};

export default useVerifyAction;
