import React, { useRef } from 'react';
import { FieldProps } from 'formik';
import { FaCamera, FaPen } from 'react-icons/fa';
import Button from '../Button';
import Compressor from 'compressorjs';

interface Props extends FieldProps {
  className?: string;
  maxWidth?: number;
  maxHeight?: number;
}

function FormikImageUpload(props: Props) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { field, form } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files && e.currentTarget.files.length >= 1) {
      const file: File = e.currentTarget.files[0];
      new Compressor(file, {
        maxWidth: props.maxWidth ?? 800,
        maxHeight: props.maxHeight ?? 800,
        convertSize: 1000000,
        success(result) {
          form.setFieldValue(field.name, result);
        },
        error(err) {
          console.log(err.message);
        },
      });
    }
  };

  const processCustomClassName = () => {
    if (!props.className) {
      return `aspect-w-2 aspect-h-1`;
    }
    const addAspectW = !props.className.includes('aspect-w');
    const addAspectH = !props.className.includes('aspect-h');
    return `${props.className} ${addAspectW ? 'aspect-w-2' : ''} ${
      addAspectH ? 'aspect-h-1' : ''
    }`;
  };

  const renderImg = () => {
    if (typeof field.value === 'string')
      return <img src={field.value} className="object-cover rounded-md" />;
    return (
      <img
        src={URL.createObjectURL(field.value)}
        className="object-cover rounded-md"
      />
    );
  };

  return (
    <div>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        name="upload-photo"
        type="file"
        onChange={o => handleChange(o)}
        accept="image/*"
      />
      <div
        className={`bg-gray-100 rounded-md flex items-center justify-center relative ${processCustomClassName()} `}
      >
        <div
          style={{ left: 'calc( 100% - 2rem)', top: '-1rem' }}
          className="absolute w-10 h-10  z-10"
        >
          <Button
            variant="outlined"
            shape="circle"
            type="button"
            className="shadow-md bg-white"
            onClick={() => inputRef.current?.click()}
          >
            <div>
              <FaPen size="14" />
            </div>
          </Button>
        </div>
        {field.value ? (
          renderImg()
        ) : (
          <div className="flex">
            <FaCamera className={`text-gray-300 text-4xl m-auto`} />
          </div>
        )}
      </div>
      {form.errors[field.name] && (
        <p style={{ color: 'red' }}>{form.errors[field.name]}</p>
      )}
    </div>
  );
}

export default FormikImageUpload;
