import useMessageError from './messangers/useMessageError';
import useMessageSuccess from './messangers/useMessageSuccess';
import useSnackbarError from './messangers/useSnackbarError';
import useSnackbarSuccess from './messangers/useSnackbarSuccess';
import useSpinner from './messangers/useSpinner';
import { Messages } from './useSuperMutation';

export interface SuperOptions {
  showSpinner?: boolean;
  showSuccessMessage?: boolean;
  showErrorMessage?: boolean;
  successMessageType?: 'snackbar' | 'message';
  errorMessageType?: 'snackbar' | 'message';
}

function useSuperOptions(options: SuperOptions & { messages?: Messages }) {
  const showSnackbarSuccess = useSnackbarSuccess();
  const showMessageSuccess = useMessageSuccess();
  const showSnackbarError = useSnackbarError();
  const showMessageError = useMessageError();
  const spinner = useSpinner();

  const _options = options;

  const showSuccesMessage = () => {
    if (_options.showSuccessMessage) {
      if (_options.successMessageType === 'snackbar') {
        showSnackbarSuccess(options.messages?.success);
      }
      if (_options.successMessageType === 'message') {
        showMessageSuccess(options.messages?.success);
      }
    }
  };

  const showErrorMessage = (error: Error) => {
    if (_options.showErrorMessage) {
      if (_options.errorMessageType === 'snackbar') {
        showSnackbarError(error, options.messages?.error);
      }
      if (_options.errorMessageType === 'message') {
        showMessageError(error, options.messages?.error);
      }
    }
  };

  const showSpinner = () => {
    if (_options.showSpinner) {
      console.log('SHOW');
      spinner.showSpinner();
    }
  };

  const hideSpinner = () => {
    if (_options.showSpinner) {
      console.log('HIDE');
      spinner.hideSpinner();
    }
  };

  return {
    showSuccesMessage,
    showErrorMessage,
    showSpinner,
    hideSpinner,
  };
}

export default useSuperOptions;
