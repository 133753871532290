import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import {
  FaCheckCircle,
  FaExclamationCircle,
  FaInfoCircle,
  FaTimesCircle,
} from 'react-icons/fa';

interface Props {
  title?: string;
  message: string;
  type: 'error' | 'success' | 'info' | 'warning';
  isVisible: boolean;
}

export default function Message({ title, message, type, isVisible }: Props) {
  const icons = {
    warning: FaExclamationCircle,
    info: FaInfoCircle,
    success: FaCheckCircle,
    error: FaTimesCircle,
  };
  const typeToColor = {
    warning: 'orange',
    info: 'blue',
    success: 'green',
    error: 'red',
  };
  const Icon = icons[type];
  return (
    <Dialog open={isVisible} keepMounted fullWidth>
      <div className={`border-l-4 border-${typeToColor[type]}-400 `}>
        <div
          className={`flex bg-${typeToColor[type]}-100 p-8 items-center justify-center`}
        >
          <Icon className={`text-${typeToColor[type]}-400`} size="36" />
        </div>

        <div className="p-8 flex flex-col space-y-2 items-center">
          {title && (
            <div className="text-gray-700 text-xl font-semibold">{title}</div>
          )}

          <div className="text-gray-400 text-sm">{message}</div>
        </div>
      </div>
    </Dialog>
  );
}
