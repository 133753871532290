import React, { FC, useContext } from 'react';
import useBaseMessage, { MessageType } from './useBaseMessage';

interface Context {
  showMessage: (args: MessageType) => void;
}

const SnackbarContext = React.createContext({} as Context);

interface Props {
  component: FC<Omit<MessageType, 'duration'> & { isVisible: boolean }>;
  children: React.ReactNode;
}

export const SnackbarProvider: FC<Props> = ({
  component: Component,
  children,
}) => {
  const { showMessage, isVisible, state } = useBaseMessage();

  return (
    <SnackbarContext.Provider value={{ showMessage }}>
      <Component {...state} isVisible={isVisible} />
      {children}
    </SnackbarContext.Provider>
  );
};

const useCustomSnackbar = (): Context['showMessage'] => {
  const context = useContext(SnackbarContext);
  if (!context.showMessage) {
    throw new Error('useSnackbar must be used inside SnackbarProvider');
  }
  return context.showMessage;
};

export default useCustomSnackbar;
