import React from 'react';
import './tailwind.css';

interface Props {
  children: React.ReactNode;
  className?: string;
  color?: string;
  shape?: 'rounded' | 'pill';
  variant?: 'contained' | 'light';
  size?: 'sm' | 'md' | 'lg';
}

const Chip = ({
  color = 'primary',
  shape = 'rounded',
  variant = 'light',
  size = 'sm',
  children,
}: Props) => {
  const classes = {
    contained: `text-white ${`bg-${color}-400`}`,
    light: `${`text-${color}-400 bg-${color}-100`}`,
  };
  const shapes = {
    rounded: 'rounded-md px-2 py-1',
    pill: 'rounded-full px-2 py-1',
  };
  const sizes = {
    sm: 'text-xs',
    md: 'text-sm',
    lg: 'text-lg',
  };
  return (
    <span
      className={
        'font-semibold text-center ' +
        classes[variant] +
        ' ' +
        shapes[shape] +
        ' ' +
        sizes[size]
      }
    >
      {children}
    </span>
  );
};

export default Chip;
